<template>
    <div>
        <modal-invite-post-users
            id="modal-invite-post-users"
            :program="program"
            @success="modalSuccess"
        />

        <b-card>
            <div class="d-flex justify-content-between align-items-center pb-3">
                <b-card-title
                    title-tag="h2"
                    class="mb-0"
                >
                    {{ $t('groups.clients.title') }}
                </b-card-title>

                <div>
                    <b-button
                        v-b-modal="'modal-invite-post-users'"
                        variant="dark"
                    >
                        +
                    </b-button>
                </div>
            </div>

            <members-table
                :collection="postUsers"
                :include-fields="['name', 'createdAt', 'actions']"
            >
                <template #cell(name)="{item}">
                    {{ item.user.name }}
                </template>

                <template #cell(actions)="{item}">
                    <b-button
                        variant="light"
                        size="sm"
                        @click="deletePostUser(item)"
                    >
                        <fa
                            class="text-danger"
                            :icon="['fac', 'trash']"
                        />
                    </b-button>
                </template>
            </members-table>
        </b-card>
    </div>
</template>

<script>
import {Program} from '@/models/Program';
import {PostUsers} from '@/models/PostUser';
import MembersTable from '@/components/members/Table';
import ModalInvitePostUsers from '@/components/programs/ModalInvitePostUsers';

export default {
    name: 'ProgramEditClients',
    components: {MembersTable, ModalInvitePostUsers},
    props: {
        program: {
            type: Program,
            required: true,
        },
    },
    data: function() {
        return {
            postUsers: new PostUsers([], {
                routePrefix: this.program.getFetchURL(),
            }),
        };
    },
    watch: {
        'postUsers.models'() {
            this.program.set('clients', this.postUsers);
        },
    },
    methods: {
        async deletePostUser(postUser) {
            const confirmed = await this.$confirmChallenge({
                message: this.$t('common.messages.removeConfirmation', {
                    object: postUser.user.name,
                }),
            });

            if (confirmed) {
                postUser.on('delete', () => {
                    this.postUsers.remove(postUser);
                });

                await postUser.delete();
            }
        },
        modalSuccess() {
            this.postUsers.clear();

            this.postUsers.page(0).fetch();
        },
    },
};
</script>
