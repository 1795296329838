import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import Model from './vue-mc/Model';
import Collection from './vue-mc/Collection';

export class PostUser extends Model {
    defaults() {
        return {
            id: null,
        };
    }

    options() {
        return {
            endpoint: 'post-users',
        };
    }
};

export const PostUsers = applyAttributesMixin(class PostUsers extends Collection {
    options() {
        return {
            model: PostUser,
        };
    }
});
