<template>
    <div>
        <b-form-group>
            <b-form-input
                v-model="input"
                :placeholder="$t('users.add.fields.email.placeholder')"
                @keypress.enter="onAddSuggestion"
            />

            <div class="mt-3">
                <div
                    v-if="addEmailsSuggestion.length >= 1"
                    class="bg-light border-radius p-3 cursor-pointer"
                    @click="onAddSuggestion"
                >
                    {{ $t('users.add.addEmails', {emails: addEmailsSuggestion.join(', ')}) }}
                </div>

                <div
                    v-else
                    class="text-muted"
                >
                    {{ $t('users.add.fields.email.hint') }}
                </div>
            </div>
        </b-form-group>

        <b-list-group>
            <b-list-group-item
                v-for="(email, index) in emails"
                :key="email"
                :variant="!!errors[index] ? 'danger' : ''"
            >
                <b-row>
                    <b-col>
                        {{ email }}
                    </b-col>

                    <b-col cols="auto">
                        <fa
                            icon="times"
                            class="cursor-pointer"
                            @click="removeEmail(index)"
                        />
                    </b-col>
                </b-row>

                <div
                    v-if="errors[index]"
                    class="small opacity-50"
                >
                    {{ errors[index] }}
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import {uniq} from 'lodash';

export default {
    name: 'InputEmailsList',
    props: {
        /**
         * An object of errors for the emails. The property represents the key
         * in the emails array.
         */
        errors: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            emails: [],
            input: '',
        };
    },
    computed: {
        addEmailsSuggestion() {
            return this.extractEmails(this.input).emails;
        },
    },
    watch: {
        emails() {
            this.$emit('input', this.emails);
        },
    },
    methods: {
        /**
         * Extracts emails (everything separated by one or more space)
         *
         * @param {string} value
         * @returns {{ emails: string[], stripped: string }}
         */
        extractEmails(value) {
            const patternEmail = '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';

            const endOrSpace = '\\s+|$';

            const patternInclusive = new RegExp(`${patternEmail}(${endOrSpace})`, 'g');

            const pattern = new RegExp(`${patternEmail}(?=${endOrSpace})`, 'g');

            const match = value.match(pattern);

            if (match) {
                const emails = uniq(match.map(match => match));

                const stripped = value.replace(patternInclusive, () => '');

                return {
                    emails,
                    stripped,
                };
            }

            return {emails: [], stripped: value};
        },
        onAddSuggestion() {
            const {emails, stripped} = this.extractEmails(this.input);

            this.emails = uniq([...this.emails, ...emails]);

            this.input = stripped;
        },
        removeEmail(index) {
            this.emails.splice(index, 1);

            this.$emit('removed', index);
        },
    },
};
</script>
