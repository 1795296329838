import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import {Post, Posts} from './Post';
import Model from './vue-mc/Model';
import Collection from './vue-mc/Collection';
import PostPrivacy from '@/library/enumerations/PostPrivacy';

export class Chapter extends Post {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            title: {},
            body: {},
            privacy: PostPrivacy.RELATED_TO_POST,
            posts: [],
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            patch: false,
            endpoint: 'chapters',
        };
    }

    /**
     * Mapping of model attributes to the class it should be transformed into.
     *
     * @returns {Record<string, Constructor>}
     */
    transformations() {
        return {
            posts: Posts,
        };
    }

    /**
     * @returns {Object} The data to send to the server when saving this model.
     */
    getSaveData() {
        let data = {
            title: this.title,
            body: this.body,
            privacy: this.privacy,
            post_ids: this.posts.map(post => post.id),
        };

        // Avoid sending {id: null} to API.
        if (this.id) data.id = this.id;

        return data;
    }
};

export const Chapters = applyAttributesMixin(class Chapters extends Collection {
    withTranslations = true;

    /**
     * Options of this collection.
     *
     * @return {Object}
     */
    options() {
        return {
            model: Chapter,
        };
    }

    getSaveQuery() {
        const query = super.getFetchQuery();

        if (this.withTranslations) {
            query.withTranslations = '1';
        }

        return query;
    }

    /**
     * @returns {Array} The data to use for saving.
     */
    getSaveData() {
        const data = super.getSaveData();

        // API needs the 'chapters' key instead of just an array.
        return {
            chapters: data,
        };
    }
});
