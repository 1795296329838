import {Post} from '@/models/Post';
import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import {Chapters} from './Chapter';
import Collection from './vue-mc/Collection';
import PostPrivacy from '@/library/enumerations/PostPrivacy';

export class Program extends Post {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            title: {},
            body: {},
            locale: 'nl',
            privacy: PostPrivacy.RELATED_TO_POST,
            customAttributes: {
                color: '#08cb78', // MoNoA primary green
            },
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'programs',
        };
    }

    /**
     * API endpoints of this model.
     *
     * @return {object}
     */
    routes() {
        return {
            delete: 'posts/{id}',
        };
    }

    transformations() {
        return {
            chapters: Chapters,
        };
    }
};

export const Programs = applyAttributesMixin(class Programs extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Program,
        };
    }
});
