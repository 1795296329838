<template>
    <b-modal
        v-bind="$attrs"
        centered
        :title="title"
    >
        <b-form @submit.prevent="submit">
            <input-emails-list
                v-model="postUser.emails"
                :errors="errors"
                @removed="postUser._errors = {}"
            />
        </b-form>

        <template #modal-footer="{cancel}">
            <b-btn
                class="flex-grow-1"
                variant="light"
                @click="cancel"
            >
                {{ $t('buttons.cancel') }}
            </b-btn>

            <btn-resource
                class="flex-grow-1"
                variant="primary"
                :resource="postUser"
                @click="submit"
            >
                {{ title }}
            </btn-resource>
        </template>
    </b-modal>
</template>

<script>
import {Program} from '@/models/Program';
import {PostUser} from '@/models/PostUser';
import Role from '@/library/enumerations/Role';
import {detainFeedback} from '@/library/helpers';
import InputEmailsList from '@/components/common/form/InputEmailsList';

export default {
    name: 'ProgramsModalInvitePostUsers',
    components: {InputEmailsList},
    props: {
        program: {
            type: Program,
            required: true,
        },
    },
    data() {
        return {
            postUser: new PostUser(
                {emails: []},
                null,
            ),
        };
    },
    computed: {
        errors() {
            return Object.entries(this.postUser._errors)
                .filter(([key]) => key.startsWith('emails.'))
                .reduce((errors, [key, value]) => {
                    errors[key.split('.')[1]] = value[0];

                    return errors;
                }, {});
        },
        title() {
            const translatedRole = Role.$t(Role.CLIENT, 'plural').toLowerCase();

            return this.$t('users.add.title', {type: translatedRole});
        },
    },
    methods: {
        async submit() {
            await this.postUser.save({
                method: 'PATCH',
                url: `/${this.program.getFetchURL()}`
                    + `/${this.postUser.getOption('endpoint')}`,
            });

            this.$emit('success');

            detainFeedback(() => {
                this.$bvModal.hide(this.$attrs.id);
            });
        },
    },
};
</script>
