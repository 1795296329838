var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll.document",value:(_vm.fetchCollection),expression:"fetchCollection",modifiers:{"document":true}}],attrs:{"fields":_vm.tableFields,"sort-by":_vm.collection.sortBy,"sort-desc":_vm.collection.sortDesc,"items":_vm.collection.models,"busy":_vm.collection.loading && _vm.collection.models.length <= 0,"tbody-tr-class":_vm.getTrClass,"no-local-sorting":"","hover":"","responsive":"","show-empty":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.collection, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.collection, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.collection, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.collection, "sortDesc", $event)},"row-clicked":function($event){return _vm.onRowClicked($event)}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('spinner-block')]},proxy:true},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._t("cell(name)",[_vm._v(" "+_vm._s(_vm.getNameValue(item))+" ")],{"item":item})]}},{key:"cell(createdAt)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$moment(value).fromNow())+" ")]}},{key:"cell(roles)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rolesAsString)+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("mr-1 circle circle-xs bg-" + (item.status.color))}),_vm._v(" "+_vm._s(item.status.text)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_vm._t("cell(actions)",[(_vm.canDeleteMember(item))?_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"light","size":"sm"},on:{"click":function($event){return _vm.deleteMember(item)}}},[_c('fa',{staticClass:"text-danger",attrs:{"icon":['fac', 'trash']}})],1)],1):_vm._e()],{"item":item})]}}],null,true)}),(_vm.collection.loading && _vm.collection.models.length > 0)?_c('spinner-block'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }