<template>
    <div>
        <b-table
            v-infinite-scroll.document="fetchCollection"
            :fields="tableFields"
            :sort-by.sync="collection.sortBy"
            :sort-desc.sync="collection.sortDesc"
            :items="collection.models"
            :busy="collection.loading && collection.models.length <= 0"
            :tbody-tr-class="getTrClass"
            no-local-sorting
            hover
            responsive
            show-empty
            @row-clicked="onRowClicked($event)"
        >
            <!-- Table busy state -->
            <template #table-busy>
                <spinner-block />
            </template>

            <template #cell(name)="{item}">
                <slot
                    :item="item"
                    name="cell(name)"
                >
                    {{ getNameValue(item) }}
                </slot>
            </template>

            <template #cell(createdAt)="{value}">
                {{ $moment(value).fromNow() }}
            </template>

            <template #cell(roles)="{item}">
                {{ item.rolesAsString }}
            </template>

            <template #cell(status)="{item}">
                <span :class="`mr-1 circle circle-xs bg-${item.status.color}`" />

                {{ item.status.text }}
            </template>

            <template #cell(actions)="{item}">
                <slot
                    :item="item"
                    name="cell(actions)"
                >
                    <div
                        v-if="canDeleteMember(item)"
                        class="d-flex justify-content-end"
                    >
                        <b-button
                            variant="light"
                            size="sm"
                            @click="deleteMember(item)"
                        >
                            <fa
                                class="text-danger"
                                :icon="['fac', 'trash']"
                            />
                        </b-button>
                    </div>
                </slot>
            </template>
        </b-table>

        <spinner-block v-if="collection.loading && collection.models.length > 0" />
    </div>
</template>

<script>
import Collection from '@/models/vue-mc/Collection';
import QueryableCollection from '@/components/common/mixins/QueryableCollection';

export default {
    name: 'MembersTable',
    mixins: [QueryableCollection],
    props: {
        /**
         * The collection to use for pagination.
         */
        collection: {
            type: Collection,
            required: true,
        },
        includeFields: {
            type: Array,
            default: undefined,
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: this.$t('users.model.name.label'),
                    tdClass: 'font-weight-bold',
                    sortable: true,
                },
                {
                    key: 'email',
                    label: this.$t('users.model.email.label'),
                    tdClass: 'font-weight-bold',
                    sortable: true,
                },
                {
                    key: 'groupName',
                    label: this.$t('common.words.organization'),
                },
                {
                    key: 'relatedProgramsCount',
                    label: this.$t('programs.programs'),
                },
                {
                    key: 'relatedMediaCount',
                    label: this.$t('common.words.media'),
                },
                {
                    key: 'createdAt',
                    label: this.$t('common.words.addedAt'),
                },
                {
                    key: 'roles',
                    label: this.$t('users.model.roles.label'),
                },
                {
                    key: 'status',
                },
                {
                    key: 'actions',
                    tdClass: 'w-auto',
                    label: '',
                },
            ],
        };
    },
    computed: {
        tableFields() {
            if (this.includeFields === undefined) {
                return this.fields
                    // Do not show actions column by default.
                    .filter(f => f.key !== 'actions');
            }

            return this.fields
                .filter(f => this.includeFields.includes(f.key));
        },
    },
    async mounted() {
        await this.fetchCollectionUntilScrollable();
    },
    methods: {
        canDeleteMember(member) {
            return ['group-users', 'invitations'].includes(member.type);
        },
        async deleteMember(member) {
            const confirmed = await this.$confirmChallenge({
                message: this.$t('common.messages.removeConfirmation', {
                    object: member.name || member.email,
                }),
            });

            if (confirmed) {
                const model = member.toTypeModel();

                model.on('delete', () => {
                    this.collection.remove(member);
                });

                await model.delete();
            }
        },
        getNameValue(member) {
            const fields = this.tableFields
                .filter(f => ['name', 'email'].includes(f.key));

            if (fields.length === 2) {
                return member.name;
            }

            return member.name || member.email;
        },
        getTrClass(member) {
            if(!member) return;

            return this.isClickable(member) ? 'cursor-pointer' : '';
        },
        isClickable(member) {
            return ['group-users', 'users'].includes(member.type);
        },
        onRowClicked(member) {
            if (this.isClickable(member)) {
                const name = member.type === 'group-users'
                    ? 'edit'
                    : 'show';

                this.$router.push(member.getLocation(name));
            }
        },
    },
};
</script>
